import { NextRouter } from "next/router";
import { navigateToPath } from "./DOMFunctions";
import { currentUrl } from "./window";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

//add or update the params key values in the path sections of a url string
export function appendURLParams(path: string = currentUrl(), paramObj?: Record<string, string | undefined>): string {
  // Split the path into two parts: everything before the "?" and everything after.
  const [basePath, queryString] = path.split("?");

  // Use URLSearchParams to easily manipulate query parameters.
  const urlParams = new URLSearchParams(queryString || "");

  // Loop through each key-value pair in the provided object and update/add it to urlParams.
  for (const [key, value] of Object.entries(paramObj ?? {})) {
    if (value === undefined) {
      //remove the param
      urlParams.delete(key);
    } else {
      urlParams.set(key, value);
    }
  }

  // If there's at least one query parameter after updating, append it to the basePath.
  return urlParams.toString() ? `${basePath}?${urlParams.toString()}` : basePath;
}

export function getURLParams(url?: string): Record<string, string> {
  if (!url) url = currentUrl();

  // Extract the query string part after the '?'
  const queryString = url.includes("?") ? url.split("?")[1] : "";
  if (!queryString) return {};

  const urlParams = new URLSearchParams(queryString);
  const params: Record<string, string> = {};
  urlParams.forEach((value, key) => {
    params[key] = value;
  });
  urlParams.getAll("key");
  return params;
}

export function removeURLParams(path: string, paramKeys: string[]): string {
  // Split the path into two parts: everything before the "?" and everything after.
  const [basePath, queryString] = path.split("?");

  // Use URLSearchParams to easily manipulate query parameters.
  const urlParams = new URLSearchParams(queryString || "");

  // Loop through each key in the provided array and remove it from urlParams.
  for (const key of paramKeys) {
    urlParams.delete(key);
  }

  // If there's at least one query parameter after updating, append it to the basePath.
  return urlParams.toString() ? `${basePath}?${urlParams.toString()}` : basePath;
}

export function navigateToURL(router?: NextRouter | AppRouterInstance, path?: string, keepParams: Record<string, string> | boolean = true) {
  if (keepParams === false) {
    //navigate without params
    navigateToPath(router, path);
  } else if (keepParams === true) {
    //naviate with all params
    navigateToUrlKeepParams(router, path);
  } else {
    //navigate with the passed param
    navigateToUrlKeepParams(router, path, keepParams);
  }
}

export function navigateToURLWithParams(router?: NextRouter | AppRouterInstance, path?: string, paramObj: Record<string, string> = getURLParams()): void {
  const newPath = appendURLParams(path ?? currentUrl(), paramObj);

  navigateToPath(router, newPath);
}

export function navigateToUrlKeepParams(router?: NextRouter | AppRouterInstance, path?: string, additionalParams?: Record<string, string>): void {
  //extract the query params from the current path
  const currentQueryParams = getURLParams();

  // Merge current query params with additional params
  const mergedParams = { ...currentQueryParams, ...additionalParams };

  navigateToURLWithParams(router, path, mergedParams);
}

/**
 * Returns true if `str` is a valid URL; otherwise false.
 */
export function isUrl(str: string): boolean {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
}
